<template>
	<div class="body">
		<!-- PC头部 -->
		<div class="homeheader" v-if="!Homeflage">
			<div class="header-left">
				<img @click="$router.push('/')" style="cursor: pointer;width: 50%;height: 70%;"
					src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/LOGO/RHLogo.jpg">
				<p style=" margin: 0;font-weight: bold;">融恒官方商城</p>
			</div>
			<div class="header-center">
				<div class="zt">
					<div class="tit">
						<a href="#1">首页</a>
					</div>
					<div class="tit">
						<a href="#2">软件</a>
					</div>
					<div class="tit">
						<a href="#3">行业软件</a>
					</div>
					<div class="tit">
						<a href="#4">增值服务</a>
					</div>
					<div class="tit">
						其他
					</div>
				</div>
			</div>
			<div class="header-right">
				<el-input placeholder="请输入内容" @change='search' prefix-icon="el-icon-search" v-model="selinp">
				</el-input>
			</div>
		</div>
		<div id="main">
			<div class="banner" id="1">
				<el-carousel height="600px">
					<el-carousel-item v-for="(item,i) in imglist" :key="i">
						<img style="width: 100%; height: 100%;" :src="item.url">
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="hotshop_box" id="2">
				<div class='title'>
					热销商品
				</div>
				<div class="hotshop_box_flex">
					<div class="left" v-for="(item,i) in Topone" :id="'ids'+item.id" @click='detali(item)'>
						<img :src="item.Url">
						<div class="top1box">
							<p style="width: 100%; text-align: center;color: #55585E; font-size: 18px;">{{item.Name}}
							</p>
							<p style="width: 100%; text-align: center;color: #fd3f31;">￥{{item.Price}}<span
									style="font-size: 14px; color: #888888;"> 起</span></p>
						</div>
					</div>
					<div class="right">
						<div class="right_flex" v-for="(iten ,i) in hotshop" :id="'ids'+iten.id" @click='detali(iten)'>
							<img :src="iten.Url">
							<div class="shopnabox">
								<span style="color: #55585E;font-size: 18px;">
									{{iten.Name}}
								</span>
								<span style="color: #fd3f31;">
									￥{{iten.Price}}<span style="font-size: 14px; color: #888888;"> 起</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="hotshoxbottom">
				<div class="right_flex" v-for="(iten ,i) in hotshopTW" :id="'ids'+iten.id" @click='detali(iten)'>
					<img :src="iten.Url">
					<div class="shopnabox">
						<span style="color: #55585E;font-size: 18px;">
							{{iten.Name}}
						</span>
						<span style="color: #fd3f31;">
							￥{{iten.Price}}<span style="font-size: 14px; color: #888888;"> 起</span>
						</span>
					</div>
				</div>
			</div>
			<div class="advertone">

			</div>
			<div class="severbox" id="3">
				<div class="tit">
					行业软件
				</div>
				<div class="shopbox">
					<div class="shop_flex_box" v-for="(item,i) in shoplist" :id="'ids'+item.id" @click='detali(item)'>
						<img :src="item.Url">
						<div class="shopnabox">
							<span style="color: #55585E;font-size: 18px;">
								{{item.Name}}
							</span>
							<span style="color: #fd3f31;">
								￥{{item.Price}}<span style="font-size: 14px; color: #888888;"> 起</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="Gg">

		</div>
		<div class="vas" id="4">
			<div class="tit">
				增值服务
			</div>
			<div class="shopbox">
				<div class="shop_flex_box" v-for="(item,i) in valueaddedservices" :id="'ids'+item.id"
					@click='detali(item)'>
					<img :src="item.Url">
					<div class="shopnabox">
						<span style="color: #55585E;font-size: 18px;">
							{{item.Name}}
						</span>
						<span style="color: #fd3f31;">
							￥{{item.Price}}<span style="font-size: 14px; color: #888888;"> 起</span>
						</span>
					</div>
				</div>
			</div>
		</div>
		<div id="flboxleft" @click="TEX">
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/hz.png">
			<p>分享商品</p>
		</div>
		<el-dialog title="请输入你的邀请码" :visible.sync="YQcodeflage" width="30%" center>
			<el-input placeholder="请输入邀请码" v-model="inputcode" clearable>
			</el-input>
			<!-- <p class="codurl">{{corul}}</p> -->
			<span slot="footer" class="dialog-footer">
				<el-button @click="YQcodeflage = false">取 消</el-button>
				<el-button type="primary" @click="YQcodefun">确 定</el-button>
			</span>
		</el-dialog>
		<footers></footers>
	</div>
</template>

<script>
	import footers from '../FOOTER.vue'
	const axi = require('../../assets/axi.js')
	export default {
		components: {
			footers
		},
		data() {
			return {
				YQcodeflage: false,
				inputcode: "",
				activeIndex: '1',
				screenWidth: document.body.clientWidth,
				timer: false,
				Homeflage: false,
				drawer: false, //手机导航栏
				selinp: '',
				// banner图片
				imglist: [{
					url: "https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Shopbanner.jpg"
				}],
				// 热销商品
				Topone: [],
				hotshop: [],
				//行业软件
				shoplist: [],
				// 增值服务
				valueaddedservices: [],
				hotshopTW: [], //热销商品切除前五个
			}
		},
		mounted() {
			console.log(this.$route.query.code)
			if (this.$route.query.code) {
				this.$store.commit('Invitationcodefun', this.$route.query.code)
			}
			axi.post('Commodity/Goods', {}).then((res) => {
				console.log(res)
				if (res.data.Code == 1000) {
					// this.Topone=res.data.Dto.hotshop[0]
					let arr = []
					let hotshops = []
					arr.push({
						Grouping: res.data.Dto.hotshop[0].Grouping,
						Key: res.data.Dto.hotshop[0].Key,
						Name: res.data.Dto.hotshop[0].Name,
						Price: res.data.Dto.hotshop[0].Price,
						Unit: res.data.Dto.hotshop[0].Unit,
						Url: res.data.Dto.hotshop[0].Url,
						Type: res.data.Dto.hotshop[0].Type
					})
					this.Topone = arr
					hotshops = res.data.Dto.hotshop
					hotshops.splice(0, 1)
					console.log(12)
					// for(let i=0;i<9;i++){
					// 	hotshops.push(hotshops[i])
					// }
					console.log(hotshops)
					this.hotshop = hotshops.splice(0, 4)
					this.hotshopTW = hotshops
					console.log(this.hotshopTW)
					this.shoplist = res.data.Dto.shoplist
					this.valueaddedservices = res.data.Dto.valueaddedservices
				} else {
					this.$message.error(res.data.Msg)
				}
			})
			const that = this
			if (window.innerWidth < 720) {
				this.Homeflage = true
			} else {
				this.Homeflage = false
			}
			window.onresize = () => {
				return (() => {
					window.screenWidth = document.body.clientWidth
					that.screenWidth = window.screenWidth
				})()

			}
		},
		methods: {
			handleSelect(key, keyPath) {
				console.log(key, keyPath);
			},
			search() {
				//concat() 连接两个或更多的数组，并返回结果。    
				//把多个数组合并为一个数组
				var sumData = [];
				sumData = sumData.concat(this.Topone).concat(this.hotshop).concat(this.shoplist).concat(this
					.valueaddedservices);
				console.log(sumData)
				let flage = false
				for (let i = 0; i < sumData.length; i++) {
					if (this.selinp == sumData[i].Name) {
						let ids = '#ids' + sumData[i].Grouping
						var anchor = this.$el.querySelector(ids)
						document.documentElement.scrollTop = anchor.offsetTop
						flage = true
					}
				}
				if (!flage) {
					this.$message.info('请输入精准名称搜索')
				}
			},
			detali(val) {
				console.log(val)
				this.$store.commit('shopidfun', val)
				this.$router.push('/productdetails')
			},
			TEX() {
				if (window.innerWidth < 720) {
					this.YQcodeflages = true
				} else {
					this.YQcodeflage = true
				}
			},
			YQcodefun() { //生成邀请码链接
				let dataurl = 'http://www.rhdgj.com/#/store?code=' + this.inputcode
				// let dataurl='http://localhost:8080/#/store?code='+this.inputcode
				this.corul = dataurl
				// console.log(dataurl)
				var input = document.createElement("input"); // js创建一个input输入框
				input.value = dataurl; // 将需要复制的文本赋值到创建的input输入框中
				document.body.appendChild(input); // 将输入框暂时创建到实例里面
				input.select(); // 选中输入框中的内容
				document.execCommand("Copy"); // 执行复制操作
				document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
				this.$message({
					type: 'success',
					message: '复制成功'
				})
				this.YQcodeflage = false
				// console.log(clipboard)
			},
		},
		watch: {
			screenWidth(val) {
				// 为了避免频繁触发resize函数导致页面卡顿，使用定时器
				if (!this.timer) {
					// 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
					this.screenWidth = val
					this.timer = true
					let that = this
					console.log(that.screenWidth)
					that.timer = false
					if (that.screenWidth < 720) {
						this.Homeflage = true
					} else {
						this.Homeflage = false
					}
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.body {
		background-color: #F4F5FA;
	}

	.homeheader {
		width: 100%;
		height: 100px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #f5f5f5;
		position: sticky;
		position: -webkit-sticky; // 兼容 -webkit 内核的浏览器
		top: 0px;
		background-color: #FFFFFF;
		z-index: 999;

		.header-left {
			width: 20%;
			height: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			img {
				height: 100%;
				max-width: 60%;
			}
		}

		.header-center {
			width: 60%;
			height: 100%;

			.zt {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				text-align: center;

				.tit {
					width: 20%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					font-size: 18px;
					transition: all .8s;

					a {
						text-decoration: none;
						color: #000;
						display: block;
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						transition: all .8s;
					}

					a:hover {
						color: #fd3f31;
					}
				}

				.tit:hover {
					color: #fd3f31;
					border-bottom: 3px solid #fd3f31;
				}
			}
		}

		.header-right {
			width: 20%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			.el-input {
				width: 54%;
			}
		}
	}

	.hotshop_box {
		width: 80%;
		height: 750px;
		margin-left: 10%;
		margin-top: 3%;

		.title {
			border-left: 3px solid #fd3f31;
			height: 5%;
			font-size: 18px;
			padding-left: 1%;
			display: flex;
			align-items: center;
		}

		.hotshop_box_flex {
			width: 100%;
			height: 92%;
			margin-top: 2%;
			display: flex;
			justify-content: space-between;

			.left {
				width: 33%;
				height: 96%;
				// background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/top1bgc.jpg');
				background-size: 100% 100%;
				// display: flex;
				// align-items: flex-end;
				// cursor: pointer;
				transition: all .8s;

				img {
					width: 100%;
					height: 100%;
				}

				.top1box {
					width: 100%;
					height: 22%;
					margin-top: -30px;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap: wrap;
					margin-top: -130px;
				}

			}

			.left:hover {
				width: 33%;
				height: 98%;
				box-shadow: 0px 8px 16px rgb(153 153 153 / 25%);
			}

			.right {
				width: 66%;
				height: 99%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;

				.right_flex {
					width: 48%;
					height: 48%;
					background-color: #FFFFFF;
					text-align: center;
					cursor: pointer;
					transition: all .8s;

					img {
						height: 50%;
						max-width: 100%;
						margin-top: 5%;
					}

					.shopnabox {
						height: 40%;
						width: 80%;
						margin-left: 10%;
						display: flex;
						justify-content: space-between;
						align-items: flex-end;
					}
				}

				.right_flex:hover {
					height: 50%;
					width: 50%;
					box-shadow: 0px 8px 16px rgb(153 153 153 / 25%);
				}
			}
		}
	}

	.advertone {
		width: 80%;
		height: 150px;
		background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/act.jpg');
		background-size: 100% 100%;
		margin-left: 10%;
		margin-top: 3%;
	}

	.severbox {
		width: 80%;
		min-height: 50px;
		margin-left: 10%;
		margin-top: 3%;

		.tit {
			width: 100%;
			height: 7%;
			display: flex;
			align-items: center;
			padding-left: 1%;
			font-size: 18px;
			border-left: 3px solid #fd3f31;
		}

		.shopbox {
			width: 100%;
			height: 90%;
			margin-top: 1%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;

			.shop_flex_box {
				width: 24%;
				height: 325px;
				margin-top: 10px;
				background-color: #FFFFFF;
				text-align: center;
				padding-top: 5%;
				cursor: pointer;
				transition: all .8s;

				img {
					height: 70%;
					max-width: 100%;
				}

				.shopnabox {
					width: 80%;
					margin-left: 10%;
					height: 15%;
					align-items: flex-end;
					display: flex;
					justify-content: space-between;
				}
			}

			.shop_flex_box:hover {
				width: 26%;
				height: 330px;
				box-shadow: 0px 8px 16px rgb(153 153 153 / 25%);
			}
		}
	}

	#Gg {
		width: 80%;
		height: 150px;
		background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/act2.jpg');
		background-size: 100% 100%;
		margin-left: 10%;
		margin-top: 3%;
		margin-bottom: 3%;
	}

	.vas {
		width: 80%;
		min-height: 500px;
		margin-left: 10%;

		.tit {
			height: 7%;
			padding-left: 1%;
			border-left: 3px solid #fd3f31;
			display: flex;
			align-items: center;
		}

		.shopbox {
			width: 100%;
			height: 90%;
			margin-top: 1%;
			display: flex;
			justify-content: flex-start;
			// align-items: center;
			cursor: pointer;
			flex-wrap: wrap;

			.shop_flex_box {
				width: 255px;
				height: 295px !important;
				padding-top: 5%;
				background-color: #FFFFFF;
				text-align: center;
				margin-right: 1.5%;
				transition: all .8s;

				img {
					height: 50%;
					max-width: 100%;
				}

				.shopnabox {
					width: 80%;
					margin-left: 10%;
					height: 40%;
					align-items: flex-end;
					display: flex;
					justify-content: space-between;
				}
			}

			.shop_flex_box:hover {
				width: 260px;
				height: 300px !important;
				box-shadow: 0px 8px 16px rgb(153 153 153 / 25%);
			}
		}

	}

	#flboxleft {
		width: 90px;
		height: 120px;
		background-color: #dcdcdc;
		cursor: pointer;
		position: fixed;
		left: 10px;
		top: 80vh;
		z-index: 99999;
		text-align: center;
		border-radius: 5px;

		img {
			width: 90px;
			height: 90px;
		}

		p {
			font-size: 18px;
			font-weight: bold;

		}
	}

	.hotshoxbottom {
		width: 80%;
		margin: auto;
		min-height: 0px;
		// background-color: #55AAFF;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-top: 12px;

		.right_flex {
			width: 31.68%;
			height: 328px;
			background-color: #FFFFFF;
			text-align: center;
			cursor: pointer;
			transition: all .8s;
			margin-left: 1.65%;
			margin-bottom: 14px;

			img {
				height: 50%;
				max-width: 100%;
				margin-top: 5%;
			}

			.shopnabox {
				height: 40%;
				width: 80%;
				margin-left: 10%;
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
			}
		}

		.right_flex:hover {
			height: 340px;
			width: 31.68%;
			box-shadow: 0px 8px 16px rgb(153 153 153 / 25%);
		}
	}
</style>
